import axios from 'axios';

const FREIGHT_AUDIT_API = 'https://freightforce.portcast.io/';
const FREIGHT_AUDIT_API_TOKEN = 'bcb9168d-732b-436a-abb2-46f3d1af065d';
export const INVOICE_TYPE = {
  EXPORT: 'EXPORT',
  IMPORT: 'IMPORT',
  FREIGHT: 'FREIGHT',
};

const freightAuditFetch = async (url: string, options = {}) => {
  const response = await axios(`${FREIGHT_AUDIT_API}${url}`, {
    ...options,
    headers: { 'access-token': FREIGHT_AUDIT_API_TOKEN },
  });
  return response;
};

export const useFreightAuditStore = defineStore('freightAuditStore', {
  state: () => ({
    invoiceBlob: null,

    invoiceList: [],
    invoiceData: {},
    invoiceType: '',
    invoiceStatus: '',
    processingStreamData: '',
    standardisedData: [],
    contractData: [],
    shippingData: [],
    processedData: 'Loading',
    customerData: [],
    auditBilledInvoiceData: [],
    auditBilledInvoiceStatus: '', // AUDITED, AUDIT_IN_PROGRESS. Empty means api call not made yet or in progress.
    auditShipmentContractDerivedData: [],
    auditShipmentContractDerivedStatus: '', // AUDITED, AUDIT_IN_PROGRESS. Empty means api call not made yet or in progress.
    auditShipmentCarrierDelayData: [],
    auditDelayByCarrierStatus: '', // AUDITED, AUDIT_IN_PROGRESS. Empty means api call not made yet or in progress.
    computedAuditDisputeSummaryHeader: [],
    computedAuditBilledInvoiceHeader: [],
    computedAuditShipmentContractHeader: [],
    computedAuditShipmentCarrierDelayHeader: [],

    computedAuditDisputeSummaryData: [],
    computedAuditBilledInvoiceData: [],
    computedAuditShipmentContractData: [],
    computedAuditShipmentCarrierDelayData: [],

    computedAuditDisputeSummaryFooter: [],
    computedAuditBilledInvoiceFooter: [],
    computedAuditShipmentContractFooter: [],
    computedAuditShipmentCarrierDelayFooter: [],
  }),

  actions: {
    setComputedAuditDisputeSummaryHeader(value: any) {
      this.computedAuditDisputeSummaryHeader = value;
    },
    setComputedAuditBilledInvoiceHeader(value: any) {
      this.computedAuditBilledInvoiceHeader = value;
    },
    setComputedAuditShipmentContractHeader(value: any) {
      this.computedAuditShipmentContractHeader = value;
    },
    setComputedAuditShipmentCarrierDelayHeader(value: any) {
      this.computedAuditShipmentCarrierDelayHeader = value;
    },

    setComputedAuditDisputeSummaryData(value: any) {
      this.computedAuditDisputeSummaryData = value;
    },
    setComputedAuditBilledInvoiceData(value: any) {
      this.computedAuditBilledInvoiceData = value;
    },
    setComputedAuditShipmentContractData(value: any) {
      this.computedAuditShipmentContractData = value;
    },
    setComputedAuditShipmentCarrierDelayData(value: any) {
      this.computedAuditShipmentCarrierDelayData = value;
    },

    setComputedAuditDisputeSummaryFooter(value: any) {
      this.computedAuditDisputeSummaryFooter = value;
    },
    setComputedAuditBilledInvoiceFooter(value: any) {
      this.computedAuditBilledInvoiceFooter = value;
    },
    setComputedAuditShipmentCarrierDelayFooter(value: any) {
      this.computedAuditShipmentCarrierDelayFooter = value;
    },
    setComputedAuditShipmentContractFooter(value: any) {
      this.computedAuditShipmentContractFooter = value;
    },

    setInvoiceBlob(data: any) {
      this.invoiceBlob = data;
    },
    async fetchInvoiceList() {
      const route = useRoute();
      const { q } = route.query;
      try {
        const response = await freightAuditFetch(`summary/${q ? `?keyword=${q}` : ''}`);
        this.invoiceList = response?.data?.slice ? response.data?.slice(0, 20) : [];
      }
      catch (error) {
        this.invoiceList = [];
        console.error('Failed to fetch invoice list:', error);
      }
    },
    fetchInvoicePdf(bookmarkId: any) {
      return freightAuditFetch(`raw_data/pdf/${bookmarkId}`);
    },
    async fetchInvoiceDataByBookmarkId(bookmarkId: any) {
      const { data }: any = await freightAuditFetch(`summary_data/${bookmarkId}`);

      this.invoiceData = data;
      this.invoiceType = data.invoice_type;
    },
    async fetchInvoiceProcessesStatus(bookmarkId: any) {
      const { data }: any = await freightAuditFetch(`raw_data/status/${bookmarkId}`);

      this.invoiceStatus = data.status;
    },
    async fetchInvoiceStandardisedData(bookmarkId: any) {
      const { data }: any = await freightAuditFetch(`standardised_data/${bookmarkId}`);

      this.standardisedData = data;
    },
    async fetchInvoiceProcessedData(bookmarkId: any) {
      const { data }: any = await freightAuditFetch(`processed_data/${bookmarkId}`);

      this.processedData = data;
    },
    async fetchInvoiceContractData(bookmarkId: any) {
      const { data }: any = await freightAuditFetch(`contract_data/${bookmarkId}`);

      this.contractData = data;
    },
    async fetchInvoiceShippingData(bookmarkId: any) {
      const { data }: any = await freightAuditFetch(`shipment_data/${bookmarkId}`);

      this.shippingData = data;
    },
    async fetchInvoiceAuditBilledInvoiceDataByBookmarkId(bookmarkId: any) {
      const { data }: any = await freightAuditFetch(`billed_invoice_data/${bookmarkId}`);
      if ('status_code' in data && data.status_code !== 200) {
        this.auditBilledInvoiceData = [];
        this.auditBilledInvoiceStatus = 'AUDIT_IN_PROGRESS';
      }
      else {
        this.auditBilledInvoiceData = data;
        this.auditBilledInvoiceStatus = 'AUDITED';
      }
    },
    async fetchInvoiceAuditShipmentContractDerivedDataByBookmarkId(bookmarkId: any) {
      const { data }: any = await freightAuditFetch(`shipment_contract_derived_data/${bookmarkId}`);

      if ('status_code' in data && data.status_code !== 200) {
        this.auditShipmentContractDerivedData = [];
        this.auditShipmentContractDerivedStatus = 'AUDIT_IN_PROGRESS';
      }
      else {
        this.auditShipmentContractDerivedData = data;
        this.auditShipmentContractDerivedStatus = 'AUDITED';
      }
    },
    async fetchInvoiceAuditShipmentCarrierDelayDataByBookmarkId(bookmarkId: any) {
      const { data }: any = await freightAuditFetch(`shipper_delay_data/${bookmarkId}`);
      if ('status_code' in data && data.status_code !== 200) {
        this.auditShipmentCarrierDelayData = [];
        this.auditDelayByCarrierStatus = 'AUDIT_IN_PROGRESS';
      }
      else {
        this.auditShipmentCarrierDelayData = data;
        this.auditDelayByCarrierStatus = 'AUDITED';
      }
    },
    fetchInvoiceFullData(bookmarkId: any) {
      this.fetchInvoiceStandardisedData(bookmarkId);
      this.fetchInvoiceProcessedData(bookmarkId);
      this.fetchInvoiceContractData(bookmarkId);
      this.fetchInvoiceShippingData(bookmarkId);
      this.fetchInvoiceAuditBilledInvoiceDataByBookmarkId(bookmarkId);
      this.fetchInvoiceAuditShipmentContractDerivedDataByBookmarkId(bookmarkId);
      this.fetchInvoiceAuditShipmentCarrierDelayDataByBookmarkId(bookmarkId);
    },
    async startInvoiceProcessedDataStream(bookmarkId: any) {
      const response: any = await freightAuditFetch(`raw_data/process/${bookmarkId}`);
      const reader = response.body?.getReader();
      if (reader) {
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            this.invoiceStatus = 'PROCESSED';
            this.fetchInvoiceFullData(bookmarkId);
            break;
          }
          if (value) {
            this.processingStreamData = new TextDecoder('utf-8').decode(value);
          }
        }
      }
    },
  },
});
